<template>
  <div v-loading.fullscreen.lock="loading" id="profile_verifications">
    <div class="top-header">
      <div class="other"></div>
      <div class="title">
        <span>{{ title }}</span>
      </div>
      <div class="close-button" @click="onCloseButton">
        <font-awesome-icon style="color: rgb(100, 100, 100); font-size: 20px" :icon="['fas', 'times']"></font-awesome-icon>
      </div>
    </div>

    <div class="container">
      <!-- START -->
      <div v-if="active_step == 'START' && direct == false" class="verification-step">
        <div class="image-container">
          <font-awesome-icon :icon="['fas', 'id-card-alt']"></font-awesome-icon>
        </div>

        <span style="display: block; font-size: 20px; margin-bottom: 5px; text-align: center">{{ start_title }}</span>
        <p>{{ start_message }}</p>
        <p style="margin-top: 10px;">Please click 'Next' to proceed.</p>

        <div style="margin-top: 20px">
          <el-button type="primary" @click="onSubmitStart" class="secondary-color" round>Next&nbsp;&nbsp;<i class="el-icon-arrow-right"></i></el-button>
        </div>
      </div>

      <!-- EMAIL -->
      <div v-if="active_step == 'EMAIL'" class="verification-step">
        <!-- Awaiting code -->
        <div style="text-align: center" class="container settings-options">
          <div class="image-container">
            <font-awesome-icon :icon="['fas', 'envelope-open-text']"></font-awesome-icon>
          </div>
          <span style="display: block; font-size: 20px; margin-bottom: 5px; text-align: center">Check your email!</span>
          <p>We have sent you a code to the email <span style="font-weight: 500">{{ userData.email }}</span>. Enter it below when you receive it. Check your spam folder if you can't find it.</p>

          <br>

          <div class="verification-inputs">
            <input @input="onAddedDigitVerificationInput('email', 0)" @keydown.backspace="onBackSpaceVerificationInput('email', 0)" v-model="email.verification_code[0]" ref="verification_input_email_0" inputmode="numeric" pattern="[0-9]*" type="text" maxlength="1"/>
            <input @input="onAddedDigitVerificationInput('email', 1)" @keydown.backspace="onBackSpaceVerificationInput('email', 1)" v-model="email.verification_code[1]" ref="verification_input_email_1" inputmode="numeric" pattern="[0-9]*" type="text" maxlength="1"/>
            <input @input="onAddedDigitVerificationInput('email', 2)" @keydown.backspace="onBackSpaceVerificationInput('email', 2)" v-model="email.verification_code[2]" ref="verification_input_email_2" inputmode="numeric" pattern="[0-9]*" type="text" maxlength="1"/>
            <input @input="onAddedDigitVerificationInput('email', 3)" @keydown.backspace="onBackSpaceVerificationInput('email', 3)" v-model="email.verification_code[3]" ref="verification_input_email_3" inputmode="numeric" pattern="[0-9]*" type="text" maxlength="1"/>
            <input @input="onAddedDigitVerificationInput('email', 4)" @keydown.backspace="onBackSpaceVerificationInput('email', 4)" v-model="email.verification_code[4]" ref="verification_input_email_4" inputmode="numeric" pattern="[0-9]*" type="text" maxlength="1"/>
            <input @input="onAddedDigitVerificationInput('email', 5)" @keydown.backspace="onBackSpaceVerificationInput('email', 5)" v-model="email.verification_code[5]" ref="verification_input_email_5" inputmode="numeric" pattern="[0-9]*" type="text" maxlength="1"/>
          </div>

          <div style="margin-top: 30px; display: flex; align-items: center; justify-content: center;">
            <el-button type="info" @click="onResendEmailVerificationCode" plain round>Resend email</el-button>
            <el-button type="primary" @click="onSubmitEmail('not_verified')" class="secondary-color" round>Verify&nbsp;&nbsp;<i class="el-icon-arrow-right"></i></el-button>
          </div>
        </div>
      </div>

      <!-- PHONE -->
      <div v-if="active_step == 'PHONE'" class="verification-step">
        <!-- To add phone number -->
        <div v-if="phone_number.status == 'not_verified'" style="text-align: center" class="container settings-options">
          <div class="image-container">
            <font-awesome-icon style="color: rgba(0,0,0,0.6)" :icon="['fas', 'phone-alt']"></font-awesome-icon>
          </div>
          <span style="display: block; font-size: 20px; margin-bottom: 5px; text-align: center">Let's start!</span>
          <p>Please enter your phone number with your country region. When you're finished click next. We will send you a one-time SMS message with a code.</p>

          <br>

          <el-select @change="onPhoneNumberInputChange(phone_number.phone_number)" style="margin-bottom: 10px" v-model="phone_number.country_code" placeholder="Select your country">
            <el-option
              v-for="code in phone_number.country_codes"
              :key="code.code"
              :label="`${code.code}  (+${code.prefix})`"
              :value="code.code">
            </el-option>
          </el-select>

          <el-input inputmode="numeric" pattern="[0-9]*" placeholder="Phone number" v-model="phone_number.phone_number" @input="onPhoneNumberInputChange"></el-input>

          <div style="margin-top: 30px">
            <el-button type="primary" @click="onSubmitPhone('verify')" class="secondary-color" round>Next&nbsp;&nbsp;<i class="el-icon-arrow-right"></i></el-button>
          </div>

        </div>


        <!-- Input code received -->
        <div v-if="phone_number.status == 'code_needed'" style="text-align: center" class="container settings-options">
          <div class="image-container">
            <font-awesome-icon style="color: rgba(0,0,0,0.6)" :icon="['fas', 'envelope-open-text']"></font-awesome-icon>
          </div>
          <span style="display: block; font-size: 20px; margin-bottom: 5px; text-align: center">Check your phone!</span>
          <p>We have sent you a code to the number <span style="font-weight: 500">{{ userData.phone_number | formattedPhoneNumber }}</span>. Enter it below when you receive it.</p>
        
          <br>

          <div class="verification-inputs">
            <input @input="onAddedDigitVerificationInput('phone_number', 0)" @keydown.backspace="onBackSpaceVerificationInput('phone_number', 0)" v-model="phone_number.verification_code[0]" ref="verification_input_phone_number_0" inputmode="numeric" pattern="[0-9]*" type="text" maxlength="1"/>
            <input @input="onAddedDigitVerificationInput('phone_number', 1)" @keydown.backspace="onBackSpaceVerificationInput('phone_number', 1)" v-model="phone_number.verification_code[1]" ref="verification_input_phone_number_1" inputmode="numeric" pattern="[0-9]*" type="text" maxlength="1"/>
            <input @input="onAddedDigitVerificationInput('phone_number', 2)" @keydown.backspace="onBackSpaceVerificationInput('phone_number', 2)" v-model="phone_number.verification_code[2]" ref="verification_input_phone_number_2" inputmode="numeric" pattern="[0-9]*" type="text" maxlength="1"/>
            <input @input="onAddedDigitVerificationInput('phone_number', 3)" @keydown.backspace="onBackSpaceVerificationInput('phone_number', 3)" v-model="phone_number.verification_code[3]" ref="verification_input_phone_number_3" inputmode="numeric" pattern="[0-9]*" type="text" maxlength="1"/>
            <input @input="onAddedDigitVerificationInput('phone_number', 4)" @keydown.backspace="onBackSpaceVerificationInput('phone_number', 4)" v-model="phone_number.verification_code[4]" ref="verification_input_phone_number_4" inputmode="numeric" pattern="[0-9]*" type="text" maxlength="1"/>
            <input @input="onAddedDigitVerificationInput('phone_number', 5)" @keydown.backspace="onBackSpaceVerificationInput('phone_number', 5)" v-model="phone_number.verification_code[5]" ref="verification_input_phone_number_5" inputmode="numeric" pattern="[0-9]*" type="text" maxlength="1"/>
          </div>

          <div style="margin-top: 30px; display: flex; align-items: center; justify-content: center;">
            <el-button type="info" @click="onSubmitPhone('cancel_verification')" plain round>Cancel verification</el-button>
            <el-button type="primary" @click="onSubmitPhone('check_code')" class="secondary-color" round>Verify&nbsp;&nbsp;<i class="el-icon-arrow-right"></i></el-button>
          </div>
        </div>
      </div>

      <!-- PHOTO -->
      <div v-if="active_step == 'PHOTO'" class="verification-step">
          <user-avatar-upload @onPhotoUploaded="onPhotoUploaded" :upload-location="`User/${$store.getters.userAuth.uid}/Profile/photo`" size="150px"></user-avatar-upload>
          <br>
          <p style="text-align: center">Having a profile photo will help create trust with other members, making it easier find a match. Just click above to upload a new photo.</p>
      </div>

      <!-- END -->
      <div v-if="active_step == 'END'" class="verification-step">
        <div class="image-container">
          <font-awesome-icon style="color: rgba(0,0,0,0.6)" :icon="['fas', 'check']"></font-awesome-icon>
        </div>

        <!-- Show if user wanted to verify more than one -->
        <div v-if="wanted_verifications.length > 1 || wanted_verifications.length == 0">
          <span style="display: block; font-size: 20px; margin-bottom: 5px; text-align: center">You are done!</span>
          <p>Your profile is fully verified. You can continue where you left off.</p>
          
          <div style="margin-top: 20px; display: flex; align-items: center; justify-content: center;">
            <el-button type="primary" @click="onFinishButton" class="secondary-color" round>Continue</el-button>
          </div>
        </div>

        <!-- Show if user only wanted email verification -->
        <div v-else-if="wanted_verifications[0] == 'EMAIL'">
          <span style="display: block; font-size: 20px; margin-bottom: 5px; text-align: center">You are done!</span>
          <p>Your email address is verified. {{ direct ? '' : "You can continue where you left off." }}</p>

          <div style="margin-top: 20px; display: flex; align-items: center; justify-content: center;">
            <el-button type="info" @click="onFinishButton" class="secondary-color" plain round>Close</el-button>
          </div>
        </div>

        <!-- Show if user only wanted phone verification -->
        <div v-else-if="wanted_verifications[0] == 'PHONE'">
          <span style="display: block; font-size: 20px; margin-bottom: 5px; text-align: center">You are done!</span>
          <p>Your phone number is verified. {{ direct ? '' : "You can continue where you left off." }}</p>

          <div style="margin-top: 20px; display: flex; align-items: center; justify-content: center;">
            <el-button type="info" @click="onFinishButton" class="secondary-color" plain round>Close</el-button>
          </div>
        </div>

        <!-- Show if user only wanted photo upload  -->
        <div v-else-if="wanted_verifications[0] == 'PHOTO'">
          <span style="display: block; font-size: 20px; margin-bottom: 5px; text-align: center">You are done!</span>
          <p>Thank you for uploading your profile photo. Having a profile picture will help match with members as it create a trust with each other.</p>

          <p style="margin-top: 10px" v-if="!direct">You can continue where you left off.</p>

          <div style="margin-top: 20px; display: flex; align-items: center; justify-content: center;">
            <el-button type="info" @click="onFinishButton" class="secondary-color" plain round>Close</el-button>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import lodash from 'lodash';
import { parsePhoneNumberFromString, formatIncompletePhoneNumber, getCountries, getCountryCallingCode } from 'libphonenumber-js';
import UserAvatarUpload from '@/components/UserAvatarUpload';

export default {
  components: { UserAvatarUpload },

  data: () => ({
    loading: false,
    wanted_verifications: [],
    start_title: 'Before you continue',
    start_message: "We need to verify your profile",
    verification_steps: ['EMAIL', 'PHONE', 'PHOTO'],
    active_step: '',
    redirect_back_url: null,
    continue_url: null,
    direct: true,
    prompt_on_close_button: false,
    email: {
      verification_code: ['', '', '', '', '', '']
    },
    phone_number: {
      country_code: 'IE',
      phone_number: '',
      verification_code: ['', '', '', '', '', ''],
      country_codes: getCountries().map(code => ({ code: code, prefix: getCountryCallingCode(code) })),
      status: 'not_verified' /* 'not_verified', 'code_needed' */
    },
    photo: {

    }
  }),

  async mounted() {
    // Created wanted verifications
    this.wanted_verifications = decodeURI(this.$route.query.verifications || '').split(',').filter(v => ['EMAIL', 'PHONE', 'PHOTO'].includes(v));
    // Set loading
    // setTimeout(() => this.loading = false, 350);

    if (this.$route.query.backUrl) {
      this.redirect_back_url = decodeURI(this.$route.query.backUrl);
    }

    if (this.$route.query.continueUrl) {
      this.continue_url = decodeURI(this.$route.query.continueUrl);
    }

    if (this.$route.query.startTitle) {
      this.start_title = decodeURI(this.$route.query.startTitle);
    }

    if (this.$route.query.startMessage) {
      this.start_message = decodeURI(this.$route.query.startMessage);
    }

    // If direct, don't show the START step
    if (this.$route.query.direct === 'true' || this.$route.query.direct === true) {
      this.direct = true;
    } else {
      this.direct = false;
    }

    if (!this.$route.query.verifications || decodeURI(this.$route.query.verifications).split(',').length < 1) {
      if (this.redirect_back_url) {
        return this.$router.push(this.redirect_back_url);
      } else {
        return this.$router.go(-1);
      }
    }


    if (this.wanted_verifications.length < 1) {
      if (this.redirect_back_url) {
        return this.$router.push(this.redirect_back_url); 
      } else {
        return this.$router.go(-1);
      }
    }

    await this.$store.dispatch('checkUserClaims', true);
    
    // Delete the verifications that are not needed
    this.wanted_verifications.forEach(v => {
      this.verification_steps = lodash.remove(this.verification_steps, (v) => {
        if (v == 'EMAIL') {
          return this.wanted_verifications.includes(v) && (!this.userClaims.email_verified || this.$route.query.debug === 'true');
        } else if (v == 'PHONE') {
          return this.wanted_verifications.includes(v) && (!this.userClaims.phone_verified || this.$route.query.debug === 'true');
        } else if (v == 'PHOTO') {
          return this.wanted_verifications.includes(v) && (!this.userClaims.photo_uploaded || this.$route.query.debug === 'true');
        }
      });
    });

    // Add the start form if it's not direct
    if (!this.direct) {
      this.verification_steps.unshift('START');
      this.verification_steps = JSON.parse(JSON.stringify(this.verification_steps));
    }

    // Add last view
    this.verification_steps.push('END');

    // If direct verification
    if (this.direct) {
      this.onSubmitStart();
    } else {
      // If no real steps
      if (this.real_steps.length == 0) {
        this.active_step = 'END';
      } else {
        this.active_step = 'START';
      }
    }
  },

  methods: {
    onCloseButton() {
      if (this.prompt_on_close_button) {
        this.$confirm('Are you sure you want to close?', 'Warning', {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No, cancel',
          type: 'warning'
        }).then(() => {
          if (this.redirect_back_url) {
            return this.$router.push(this.redirect_back_url); 
          } else {
            return this.$router.go(-1);
          }
        }).catch(() => {});
      } else {
        if (this.redirect_back_url) {
          return this.$router.push(this.redirect_back_url); 
        } else {
          return this.$router.go(-1);
        }
      }
    },

    onFinishButton() {
      this.$store.dispatch('checkUserClaims', true);

      if (this.continue_url) {
        return this.$router.push(this.continue_url); 
      } else {
        return this.$router.go(-1);
      }
    },

    async onSubmitStart() {
      this.loading = true;
      let new_step = this.nextPage(false);

      if (new_step === 'EMAIL') {
        // Send request to send email with code
        this.onSendEmailVerificationCode().then(r => {
          this.loading = false;
          if (!r) { return; }
          console.log(r)

          this.nextPage(true);
          setTimeout(() => { this.$refs[`verification_input_email_0`].focus() }, 500);
        });
        
      } else if (new_step === 'PHONE') {
        setTimeout(() => {
          this.loading = false;
          this.active_step = new_step;

          if (this.userData.phone_verification_uid) {
            this.phone_number.status = 'code_needed';
          }

        }, 700);
      } else if (new_step === 'PHOTO') {
        setTimeout(() => { this.loading = false; this.active_step = new_step; }, 700);
      } else if (new_step == 'END') {
        setTimeout(() => { this.loading = false; this.active_step = new_step; }, 700);
      }
    },

    onAddedDigitVerificationInput(prefix, val) {
      if (val > 4) {
        return;
      }

      // If no number
      if (this[prefix].verification_code[val] < '0' || this[prefix].verification_code[val] > '9') {
        return;
      }

      this.$refs[`verification_input_${prefix}_${Number(val) + 1}`].focus();
    },

    onBackSpaceVerificationInput(prefix, val) {
      // Can't go backwards
      if (val < 1) {
        return;
      }

      this[prefix].verification_code[val] = '';
      this.$refs[`verification_input_${prefix}_${Number(val) - 1}`].focus();
    },

    async onSubmitEmail() {
      // Send request to check code is okay
      let result;
      this.loading = true;

      try {
        result = await this.SharoAPI({ action: 'verifyEmailCode', data: { code: this.email.verification_code.join('') }});
      } catch (err) {
        console.error(err);
      }

      if (result.data.error) {
        this.$message({ type: 'error', showClose: true, message: result.data.error_message });
      } else {
        this.$message({ type: 'success', showClose: true, message: result.data.message });
        // Get new custom claims
        this.$store.dispatch('checkUserClaims', true);
        // Go new page
        this.nextPage(true);
      }

      this.loading = false;
    },

    async onSendEmailVerificationCode() {
      this.loading = true;
      let result;

      try {
        result = await this.SharoAPI({ action: 'sendVerificationEmailCode', data: {} });
        console.log(result)

        if (result.data.error) {
          this.$message({ type: 'error', showClose: true, message: result.data.error_message });
        } else {
          this.$message({ type: 'success', showClose: true, message: result.data.message });
        }

      } catch (err) {
        console.error(err);
      }

      this.loading = false;

      return result.data.success || (result.data.code == 'EMAIL_VERIFICATION_WAIT_TIME');
    },

    onResendEmailVerificationCode() {
      this.onSendEmailVerificationCode().then(r => {
        if (r) {
          this.email.verification_code = ['', '', '', '', '', ''];
        }
      });
    },

    async onSubmitPhone(status) {
      if (status == 'verify') {
        let phone_number_lib = parsePhoneNumberFromString(this.phone_number.phone_number, this.phone_number.country_code);

        // If not valid phone number
        if (!phone_number_lib.isValid()) {
          return this.$message({ type: 'error', showClose: true, message: 'Phone number is not valid, make sure the country code is correct' });
        }

        this.loading = true;
        let result;

        // Send request
        try {
          result = await this.SharoAPI({ action: 'sendVerificationPhoneCode', data: { phone_number: phone_number_lib.number } });
        } catch (err) {
          console.err(err);
        }

        // If error or success
        if (result.data.error) {
          this.$message({ type: 'error', showClose: true, message: result.data.error_message });
        } else {
          this.phone_number.status = 'code_needed';
        }

        // Set the first input number as focused
        setTimeout(() => {
          this.$refs.verification_input_phone_number_0.focus();
        }, 500);

        this.loading = false;
      } else if (status == 'check_code') {
        let code = this.phone_number.verification_code.join('');

        if (code.length < 5) {
          return this.$message({ type: 'error', showClose: true, message: 'Invalid code' });
        }

        this.loading = true;
        let result;

        try {
          result = await this.SharoAPI({ action: 'verifyPhoneCode', data: { code: code } });
        } catch (err) {
          console.error(err);
          return this.loading = false;
        }
      
        if (result.data.error) {
          this.$message({ type: 'error', showClose: true, message: result.data.error_message });
        } else {
          // Update user claims
          this.$store.dispatch('checkUserClaims', true);
          // Message
          this.$message({ type: 'success', showClose: true, message: result.data.message });
          // Next page
          this.nextPage(true);
        }

        this.loading = false;
      } else if (status == 'cancel_verification') {
        this.loading = true;

        this.SharoAPI({ action: 'cancelVerificationPhoneCode', data: {} }).then(result => {
          console.log(result);
          if (result.data.error) {
            return this.$message({ type: 'error', showClose: true, message: result.data.error_message });
          }

          this.$message({ type: 'success', showClose: true, message: result.data.message });
          this.phone_number.status = 'not_verified';
        }).finally(() => this.loading = false);
      }
    },

    onPhoneNumberInputChange(val) {
      this.phone_number.phone_number = formatIncompletePhoneNumber(val, this.phone_number.country_code);
    },

    onPhotoUploaded() {
      // Set analytics
      this.$analytics.logEvent("photo_uploaded");
      
			// Update user claims
      setTimeout(() => {
				this.$store.dispatch('checkUserClaims', true);
      }, 10000);
      
      // Go to next page
      this.nextPage(true);
    },

    nextPage(force = false) {
      let new_step = this.verification_steps[this.verification_steps.indexOf(this.active_step)+1];

      if (force) {
        this.active_step = new_step;
      }

      return new_step;
    }
  },

  computed: {
    userData() {
      return this.$store.getters.user.data;
    },

    userClaims() {
      return this.$store.getters.userClaims || {};
    },

    real_steps() {
      let steps = JSON.parse(JSON.stringify(this.verification_steps));
      return lodash.remove(steps, (v) => !['START', 'END'].includes(v));
    },

    title() {
      if (this.wanted_verifications.length > 1 || this.wanted_verifications.length == 0) {
        return 'Verify your profile';
      } else if (this.wanted_verifications[0] == 'EMAIL') {
        return 'Verify your email';
      } else if (this.wanted_verifications[0] == 'PHONE') {
        return 'Verify your phone';
      } else if (this.wanted_verifications[0] == 'PHOTO') {
        return 'Upload a photo';
      } 
    }
  },

  filters: {
    formattedPhoneNumber(val) {
      return parsePhoneNumberFromString(val || '') && parsePhoneNumberFromString(val || '').formatNational() || '';
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/theme/variables.scss";
  .top-header {
    width: 100%;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    z-index: 9998;
    background-color: white;

    position: -webkit-sticky;
    position: sticky;
    top: 0px;

    .close-button {
      cursor: pointer;
      width: 30px;
      display: flex;
      align-items: center;
      -webkit-tap-highlight-color: rgba(0,0,0,0);
      -webkit-tap-highlight-color: transparent;
      justify-content: flex-end;
      // padding: 0 10px;
    }

    .other {
      width: 30px;
      min-height: 10px;
      display: flex;
      align-items: center;
    }

    .title {
      flex-grow: 1;

      span {
        display: block;
        text-align: center;
        font-size: 19px;
      }
    }
  }

  #profile_verifications > .container {
    max-width: 600px;
    text-align: center;

    .image-container {
      border-radius: 50%;
      background-color: #d1f1ff;
      width: 100px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 40px;
      margin: auto;
      margin-bottom: 15px;

      svg {
        color: rgba(0,0,0,0.6);
      }
    }

    .verification-inputs {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: -5px;

      input {
        width: 40px;
        height: 40px;
        font-size: 18px;
        text-align: center;
        margin: 5px;
        border: 1px solid rgba(0,0,0,0.2);
        border-radius: 5px;

        &:focus {
          border: 1.5px solid $secondary-color;
          outline: none;
        }
      }
    }
  }
</style>