var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.loading,
          expression: "loading",
          modifiers: { fullscreen: true, lock: true }
        }
      ],
      attrs: { id: "profile_verifications" }
    },
    [
      _c("div", { staticClass: "top-header" }, [
        _c("div", { staticClass: "other" }),
        _c("div", { staticClass: "title" }, [
          _c("span", [_vm._v(_vm._s(_vm.title))])
        ]),
        _c(
          "div",
          { staticClass: "close-button", on: { click: _vm.onCloseButton } },
          [
            _c("font-awesome-icon", {
              staticStyle: { color: "rgb(100, 100, 100)", "font-size": "20px" },
              attrs: { icon: ["fas", "times"] }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "container" }, [
        _vm.active_step == "START" && _vm.direct == false
          ? _c("div", { staticClass: "verification-step" }, [
              _c(
                "div",
                { staticClass: "image-container" },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fas", "id-card-alt"] }
                  })
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    display: "block",
                    "font-size": "20px",
                    "margin-bottom": "5px",
                    "text-align": "center"
                  }
                },
                [_vm._v(_vm._s(_vm.start_title))]
              ),
              _c("p", [_vm._v(_vm._s(_vm.start_message))]),
              _c("p", { staticStyle: { "margin-top": "10px" } }, [
                _vm._v("Please click 'Next' to proceed.")
              ]),
              _c(
                "div",
                { staticStyle: { "margin-top": "20px" } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "secondary-color",
                      attrs: { type: "primary", round: "" },
                      on: { click: _vm.onSubmitStart }
                    },
                    [
                      _vm._v("Next  "),
                      _c("i", { staticClass: "el-icon-arrow-right" })
                    ]
                  )
                ],
                1
              )
            ])
          : _vm._e(),
        _vm.active_step == "EMAIL"
          ? _c("div", { staticClass: "verification-step" }, [
              _c(
                "div",
                {
                  staticClass: "container settings-options",
                  staticStyle: { "text-align": "center" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "image-container" },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "envelope-open-text"] }
                      })
                    ],
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        display: "block",
                        "font-size": "20px",
                        "margin-bottom": "5px",
                        "text-align": "center"
                      }
                    },
                    [_vm._v("Check your email!")]
                  ),
                  _c("p", [
                    _vm._v("We have sent you a code to the email "),
                    _c("span", { staticStyle: { "font-weight": "500" } }, [
                      _vm._v(_vm._s(_vm.userData.email))
                    ]),
                    _vm._v(
                      ". Enter it below when you receive it. Check your spam folder if you can't find it."
                    )
                  ]),
                  _c("br"),
                  _c("div", { staticClass: "verification-inputs" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.email.verification_code[0],
                          expression: "email.verification_code[0]"
                        }
                      ],
                      ref: "verification_input_email_0",
                      attrs: {
                        inputmode: "numeric",
                        pattern: "[0-9]*",
                        type: "text",
                        maxlength: "1"
                      },
                      domProps: { value: _vm.email.verification_code[0] },
                      on: {
                        input: [
                          function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.email.verification_code,
                              0,
                              $event.target.value
                            )
                          },
                          function($event) {
                            return _vm.onAddedDigitVerificationInput("email", 0)
                          }
                        ],
                        keydown: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "backspace",
                              undefined,
                              $event.key,
                              undefined
                            )
                          ) {
                            return null
                          }
                          return _vm.onBackSpaceVerificationInput("email", 0)
                        }
                      }
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.email.verification_code[1],
                          expression: "email.verification_code[1]"
                        }
                      ],
                      ref: "verification_input_email_1",
                      attrs: {
                        inputmode: "numeric",
                        pattern: "[0-9]*",
                        type: "text",
                        maxlength: "1"
                      },
                      domProps: { value: _vm.email.verification_code[1] },
                      on: {
                        input: [
                          function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.email.verification_code,
                              1,
                              $event.target.value
                            )
                          },
                          function($event) {
                            return _vm.onAddedDigitVerificationInput("email", 1)
                          }
                        ],
                        keydown: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "backspace",
                              undefined,
                              $event.key,
                              undefined
                            )
                          ) {
                            return null
                          }
                          return _vm.onBackSpaceVerificationInput("email", 1)
                        }
                      }
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.email.verification_code[2],
                          expression: "email.verification_code[2]"
                        }
                      ],
                      ref: "verification_input_email_2",
                      attrs: {
                        inputmode: "numeric",
                        pattern: "[0-9]*",
                        type: "text",
                        maxlength: "1"
                      },
                      domProps: { value: _vm.email.verification_code[2] },
                      on: {
                        input: [
                          function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.email.verification_code,
                              2,
                              $event.target.value
                            )
                          },
                          function($event) {
                            return _vm.onAddedDigitVerificationInput("email", 2)
                          }
                        ],
                        keydown: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "backspace",
                              undefined,
                              $event.key,
                              undefined
                            )
                          ) {
                            return null
                          }
                          return _vm.onBackSpaceVerificationInput("email", 2)
                        }
                      }
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.email.verification_code[3],
                          expression: "email.verification_code[3]"
                        }
                      ],
                      ref: "verification_input_email_3",
                      attrs: {
                        inputmode: "numeric",
                        pattern: "[0-9]*",
                        type: "text",
                        maxlength: "1"
                      },
                      domProps: { value: _vm.email.verification_code[3] },
                      on: {
                        input: [
                          function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.email.verification_code,
                              3,
                              $event.target.value
                            )
                          },
                          function($event) {
                            return _vm.onAddedDigitVerificationInput("email", 3)
                          }
                        ],
                        keydown: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "backspace",
                              undefined,
                              $event.key,
                              undefined
                            )
                          ) {
                            return null
                          }
                          return _vm.onBackSpaceVerificationInput("email", 3)
                        }
                      }
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.email.verification_code[4],
                          expression: "email.verification_code[4]"
                        }
                      ],
                      ref: "verification_input_email_4",
                      attrs: {
                        inputmode: "numeric",
                        pattern: "[0-9]*",
                        type: "text",
                        maxlength: "1"
                      },
                      domProps: { value: _vm.email.verification_code[4] },
                      on: {
                        input: [
                          function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.email.verification_code,
                              4,
                              $event.target.value
                            )
                          },
                          function($event) {
                            return _vm.onAddedDigitVerificationInput("email", 4)
                          }
                        ],
                        keydown: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "backspace",
                              undefined,
                              $event.key,
                              undefined
                            )
                          ) {
                            return null
                          }
                          return _vm.onBackSpaceVerificationInput("email", 4)
                        }
                      }
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.email.verification_code[5],
                          expression: "email.verification_code[5]"
                        }
                      ],
                      ref: "verification_input_email_5",
                      attrs: {
                        inputmode: "numeric",
                        pattern: "[0-9]*",
                        type: "text",
                        maxlength: "1"
                      },
                      domProps: { value: _vm.email.verification_code[5] },
                      on: {
                        input: [
                          function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.email.verification_code,
                              5,
                              $event.target.value
                            )
                          },
                          function($event) {
                            return _vm.onAddedDigitVerificationInput("email", 5)
                          }
                        ],
                        keydown: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "backspace",
                              undefined,
                              $event.key,
                              undefined
                            )
                          ) {
                            return null
                          }
                          return _vm.onBackSpaceVerificationInput("email", 5)
                        }
                      }
                    })
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "margin-top": "30px",
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "center"
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "info", plain: "", round: "" },
                          on: { click: _vm.onResendEmailVerificationCode }
                        },
                        [_vm._v("Resend email")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "secondary-color",
                          attrs: { type: "primary", round: "" },
                          on: {
                            click: function($event) {
                              return _vm.onSubmitEmail("not_verified")
                            }
                          }
                        },
                        [
                          _vm._v("Verify  "),
                          _c("i", { staticClass: "el-icon-arrow-right" })
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            ])
          : _vm._e(),
        _vm.active_step == "PHONE"
          ? _c("div", { staticClass: "verification-step" }, [
              _vm.phone_number.status == "not_verified"
                ? _c(
                    "div",
                    {
                      staticClass: "container settings-options",
                      staticStyle: { "text-align": "center" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "image-container" },
                        [
                          _c("font-awesome-icon", {
                            staticStyle: { color: "rgba(0,0,0,0.6)" },
                            attrs: { icon: ["fas", "phone-alt"] }
                          })
                        ],
                        1
                      ),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            display: "block",
                            "font-size": "20px",
                            "margin-bottom": "5px",
                            "text-align": "center"
                          }
                        },
                        [_vm._v("Let's start!")]
                      ),
                      _c("p", [
                        _vm._v(
                          "Please enter your phone number with your country region. When you're finished click next. We will send you a one-time SMS message with a code."
                        )
                      ]),
                      _c("br"),
                      _c(
                        "el-select",
                        {
                          staticStyle: { "margin-bottom": "10px" },
                          attrs: { placeholder: "Select your country" },
                          on: {
                            change: function($event) {
                              return _vm.onPhoneNumberInputChange(
                                _vm.phone_number.phone_number
                              )
                            }
                          },
                          model: {
                            value: _vm.phone_number.country_code,
                            callback: function($$v) {
                              _vm.$set(_vm.phone_number, "country_code", $$v)
                            },
                            expression: "phone_number.country_code"
                          }
                        },
                        _vm._l(_vm.phone_number.country_codes, function(code) {
                          return _c("el-option", {
                            key: code.code,
                            attrs: {
                              label: code.code + "  (+" + code.prefix + ")",
                              value: code.code
                            }
                          })
                        }),
                        1
                      ),
                      _c("el-input", {
                        attrs: {
                          inputmode: "numeric",
                          pattern: "[0-9]*",
                          placeholder: "Phone number"
                        },
                        on: { input: _vm.onPhoneNumberInputChange },
                        model: {
                          value: _vm.phone_number.phone_number,
                          callback: function($$v) {
                            _vm.$set(_vm.phone_number, "phone_number", $$v)
                          },
                          expression: "phone_number.phone_number"
                        }
                      }),
                      _c(
                        "div",
                        { staticStyle: { "margin-top": "30px" } },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "secondary-color",
                              attrs: { type: "primary", round: "" },
                              on: {
                                click: function($event) {
                                  return _vm.onSubmitPhone("verify")
                                }
                              }
                            },
                            [
                              _vm._v("Next  "),
                              _c("i", { staticClass: "el-icon-arrow-right" })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.phone_number.status == "code_needed"
                ? _c(
                    "div",
                    {
                      staticClass: "container settings-options",
                      staticStyle: { "text-align": "center" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "image-container" },
                        [
                          _c("font-awesome-icon", {
                            staticStyle: { color: "rgba(0,0,0,0.6)" },
                            attrs: { icon: ["fas", "envelope-open-text"] }
                          })
                        ],
                        1
                      ),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            display: "block",
                            "font-size": "20px",
                            "margin-bottom": "5px",
                            "text-align": "center"
                          }
                        },
                        [_vm._v("Check your phone!")]
                      ),
                      _c("p", [
                        _vm._v("We have sent you a code to the number "),
                        _c("span", { staticStyle: { "font-weight": "500" } }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("formattedPhoneNumber")(
                                _vm.userData.phone_number
                              )
                            )
                          )
                        ]),
                        _vm._v(". Enter it below when you receive it.")
                      ]),
                      _c("br"),
                      _c("div", { staticClass: "verification-inputs" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.phone_number.verification_code[0],
                              expression: "phone_number.verification_code[0]"
                            }
                          ],
                          ref: "verification_input_phone_number_0",
                          attrs: {
                            inputmode: "numeric",
                            pattern: "[0-9]*",
                            type: "text",
                            maxlength: "1"
                          },
                          domProps: {
                            value: _vm.phone_number.verification_code[0]
                          },
                          on: {
                            input: [
                              function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.phone_number.verification_code,
                                  0,
                                  $event.target.value
                                )
                              },
                              function($event) {
                                return _vm.onAddedDigitVerificationInput(
                                  "phone_number",
                                  0
                                )
                              }
                            ],
                            keydown: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "backspace",
                                  undefined,
                                  $event.key,
                                  undefined
                                )
                              ) {
                                return null
                              }
                              return _vm.onBackSpaceVerificationInput(
                                "phone_number",
                                0
                              )
                            }
                          }
                        }),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.phone_number.verification_code[1],
                              expression: "phone_number.verification_code[1]"
                            }
                          ],
                          ref: "verification_input_phone_number_1",
                          attrs: {
                            inputmode: "numeric",
                            pattern: "[0-9]*",
                            type: "text",
                            maxlength: "1"
                          },
                          domProps: {
                            value: _vm.phone_number.verification_code[1]
                          },
                          on: {
                            input: [
                              function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.phone_number.verification_code,
                                  1,
                                  $event.target.value
                                )
                              },
                              function($event) {
                                return _vm.onAddedDigitVerificationInput(
                                  "phone_number",
                                  1
                                )
                              }
                            ],
                            keydown: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "backspace",
                                  undefined,
                                  $event.key,
                                  undefined
                                )
                              ) {
                                return null
                              }
                              return _vm.onBackSpaceVerificationInput(
                                "phone_number",
                                1
                              )
                            }
                          }
                        }),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.phone_number.verification_code[2],
                              expression: "phone_number.verification_code[2]"
                            }
                          ],
                          ref: "verification_input_phone_number_2",
                          attrs: {
                            inputmode: "numeric",
                            pattern: "[0-9]*",
                            type: "text",
                            maxlength: "1"
                          },
                          domProps: {
                            value: _vm.phone_number.verification_code[2]
                          },
                          on: {
                            input: [
                              function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.phone_number.verification_code,
                                  2,
                                  $event.target.value
                                )
                              },
                              function($event) {
                                return _vm.onAddedDigitVerificationInput(
                                  "phone_number",
                                  2
                                )
                              }
                            ],
                            keydown: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "backspace",
                                  undefined,
                                  $event.key,
                                  undefined
                                )
                              ) {
                                return null
                              }
                              return _vm.onBackSpaceVerificationInput(
                                "phone_number",
                                2
                              )
                            }
                          }
                        }),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.phone_number.verification_code[3],
                              expression: "phone_number.verification_code[3]"
                            }
                          ],
                          ref: "verification_input_phone_number_3",
                          attrs: {
                            inputmode: "numeric",
                            pattern: "[0-9]*",
                            type: "text",
                            maxlength: "1"
                          },
                          domProps: {
                            value: _vm.phone_number.verification_code[3]
                          },
                          on: {
                            input: [
                              function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.phone_number.verification_code,
                                  3,
                                  $event.target.value
                                )
                              },
                              function($event) {
                                return _vm.onAddedDigitVerificationInput(
                                  "phone_number",
                                  3
                                )
                              }
                            ],
                            keydown: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "backspace",
                                  undefined,
                                  $event.key,
                                  undefined
                                )
                              ) {
                                return null
                              }
                              return _vm.onBackSpaceVerificationInput(
                                "phone_number",
                                3
                              )
                            }
                          }
                        }),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.phone_number.verification_code[4],
                              expression: "phone_number.verification_code[4]"
                            }
                          ],
                          ref: "verification_input_phone_number_4",
                          attrs: {
                            inputmode: "numeric",
                            pattern: "[0-9]*",
                            type: "text",
                            maxlength: "1"
                          },
                          domProps: {
                            value: _vm.phone_number.verification_code[4]
                          },
                          on: {
                            input: [
                              function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.phone_number.verification_code,
                                  4,
                                  $event.target.value
                                )
                              },
                              function($event) {
                                return _vm.onAddedDigitVerificationInput(
                                  "phone_number",
                                  4
                                )
                              }
                            ],
                            keydown: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "backspace",
                                  undefined,
                                  $event.key,
                                  undefined
                                )
                              ) {
                                return null
                              }
                              return _vm.onBackSpaceVerificationInput(
                                "phone_number",
                                4
                              )
                            }
                          }
                        }),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.phone_number.verification_code[5],
                              expression: "phone_number.verification_code[5]"
                            }
                          ],
                          ref: "verification_input_phone_number_5",
                          attrs: {
                            inputmode: "numeric",
                            pattern: "[0-9]*",
                            type: "text",
                            maxlength: "1"
                          },
                          domProps: {
                            value: _vm.phone_number.verification_code[5]
                          },
                          on: {
                            input: [
                              function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.phone_number.verification_code,
                                  5,
                                  $event.target.value
                                )
                              },
                              function($event) {
                                return _vm.onAddedDigitVerificationInput(
                                  "phone_number",
                                  5
                                )
                              }
                            ],
                            keydown: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "backspace",
                                  undefined,
                                  $event.key,
                                  undefined
                                )
                              ) {
                                return null
                              }
                              return _vm.onBackSpaceVerificationInput(
                                "phone_number",
                                5
                              )
                            }
                          }
                        })
                      ]),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-top": "30px",
                            display: "flex",
                            "align-items": "center",
                            "justify-content": "center"
                          }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "info", plain: "", round: "" },
                              on: {
                                click: function($event) {
                                  return _vm.onSubmitPhone(
                                    "cancel_verification"
                                  )
                                }
                              }
                            },
                            [_vm._v("Cancel verification")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "secondary-color",
                              attrs: { type: "primary", round: "" },
                              on: {
                                click: function($event) {
                                  return _vm.onSubmitPhone("check_code")
                                }
                              }
                            },
                            [
                              _vm._v("Verify  "),
                              _c("i", { staticClass: "el-icon-arrow-right" })
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e()
            ])
          : _vm._e(),
        _vm.active_step == "PHOTO"
          ? _c(
              "div",
              { staticClass: "verification-step" },
              [
                _c("user-avatar-upload", {
                  attrs: {
                    "upload-location":
                      "User/" +
                      _vm.$store.getters.userAuth.uid +
                      "/Profile/photo",
                    size: "150px"
                  },
                  on: { onPhotoUploaded: _vm.onPhotoUploaded }
                }),
                _c("br"),
                _c("p", { staticStyle: { "text-align": "center" } }, [
                  _vm._v(
                    "Having a profile photo will help create trust with other members, making it easier find a match. Just click above to upload a new photo."
                  )
                ])
              ],
              1
            )
          : _vm._e(),
        _vm.active_step == "END"
          ? _c("div", { staticClass: "verification-step" }, [
              _c(
                "div",
                { staticClass: "image-container" },
                [
                  _c("font-awesome-icon", {
                    staticStyle: { color: "rgba(0,0,0,0.6)" },
                    attrs: { icon: ["fas", "check"] }
                  })
                ],
                1
              ),
              _vm.wanted_verifications.length > 1 ||
              _vm.wanted_verifications.length == 0
                ? _c("div", [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          display: "block",
                          "font-size": "20px",
                          "margin-bottom": "5px",
                          "text-align": "center"
                        }
                      },
                      [_vm._v("You are done!")]
                    ),
                    _c("p", [
                      _vm._v(
                        "Your profile is fully verified. You can continue where you left off."
                      )
                    ]),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-top": "20px",
                          display: "flex",
                          "align-items": "center",
                          "justify-content": "center"
                        }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "secondary-color",
                            attrs: { type: "primary", round: "" },
                            on: { click: _vm.onFinishButton }
                          },
                          [_vm._v("Continue")]
                        )
                      ],
                      1
                    )
                  ])
                : _vm.wanted_verifications[0] == "EMAIL"
                ? _c("div", [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          display: "block",
                          "font-size": "20px",
                          "margin-bottom": "5px",
                          "text-align": "center"
                        }
                      },
                      [_vm._v("You are done!")]
                    ),
                    _c("p", [
                      _vm._v(
                        "Your email address is verified. " +
                          _vm._s(
                            _vm.direct
                              ? ""
                              : "You can continue where you left off."
                          )
                      )
                    ]),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-top": "20px",
                          display: "flex",
                          "align-items": "center",
                          "justify-content": "center"
                        }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "secondary-color",
                            attrs: { type: "info", plain: "", round: "" },
                            on: { click: _vm.onFinishButton }
                          },
                          [_vm._v("Close")]
                        )
                      ],
                      1
                    )
                  ])
                : _vm.wanted_verifications[0] == "PHONE"
                ? _c("div", [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          display: "block",
                          "font-size": "20px",
                          "margin-bottom": "5px",
                          "text-align": "center"
                        }
                      },
                      [_vm._v("You are done!")]
                    ),
                    _c("p", [
                      _vm._v(
                        "Your phone number is verified. " +
                          _vm._s(
                            _vm.direct
                              ? ""
                              : "You can continue where you left off."
                          )
                      )
                    ]),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-top": "20px",
                          display: "flex",
                          "align-items": "center",
                          "justify-content": "center"
                        }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "secondary-color",
                            attrs: { type: "info", plain: "", round: "" },
                            on: { click: _vm.onFinishButton }
                          },
                          [_vm._v("Close")]
                        )
                      ],
                      1
                    )
                  ])
                : _vm.wanted_verifications[0] == "PHOTO"
                ? _c("div", [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          display: "block",
                          "font-size": "20px",
                          "margin-bottom": "5px",
                          "text-align": "center"
                        }
                      },
                      [_vm._v("You are done!")]
                    ),
                    _c("p", [
                      _vm._v(
                        "Thank you for uploading your profile photo. Having a profile picture will help match with members as it create a trust with each other."
                      )
                    ]),
                    !_vm.direct
                      ? _c("p", { staticStyle: { "margin-top": "10px" } }, [
                          _vm._v("You can continue where you left off.")
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-top": "20px",
                          display: "flex",
                          "align-items": "center",
                          "justify-content": "center"
                        }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "secondary-color",
                            attrs: { type: "info", plain: "", round: "" },
                            on: { click: _vm.onFinishButton }
                          },
                          [_vm._v("Close")]
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }